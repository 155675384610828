import React from "react";

import DefaultLayout from "../../../../layouts/default";

const LEPReferenzen = () => (
  <DefaultLayout title="Referenzen – WiCare|now LEP">
    <div className="white wicare">
      <div className="navContentSpacerHelper"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li>
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>

        <nav className="subNav2">
          <div className="subNav2Product">
            <div className="productImage">
              <img
                src="/assets/images/logos/WCN-LEP.svg"
                alt="WiCare LEP Logo"
                width="40"
              />
            </div>
            <div className="productText">
              <span className="line">WiCare|LEP</span>
              <span className="line">
                <span className="is-visible-desktop">&nbsp;für mehr</span>
                &nbsp;Transparenz in der Pflege
              </span>
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links">
            <li>
              <a href="/dokumentationsloesungen/wicare-lep/">Übersicht</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-lep/technik/">Technik</a>
            </li>
            <li className="is-active">
              <a href="/dokumentationsloesungen/wicare-lep/referenzen/">
                Referenzen
              </a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-lep/downloads/">
                Downloads
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <div className="wrapper">
          <section className="has-padding-top has-padding-bottom">
            <h1 className="doc-header">
              WiCare|LEP Leistungserfassung in der Pflege
            </h1>

            <div className="referenzen">
              <article className="referenzen-item">
                <div className="referencesColorFlex3">
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    <div className="background is-green"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-doc-h/">
                    <div className="background is-blue"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-le/">
                    <div className="background is-red"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Regionalspital Surselva Ilanz (GR)
                </p>
                <p className="refKunden">Kunde seit 1997</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex3">
                  <a href="/dokumentationsloesungen/wicare-tacs/">
                    <div class="background is-purple"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-doc-h/">
                    <div className="background is-blue"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-le/">
                    <div className="background is-red"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Solothurner Spitäler AG - soH (SO)
                  <br />
                  Kantonsspital Olten
                </p>
                <p className="refKunden">Kunde seit 1998</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex3">
                  <a href="/dokumentationsloesungen/wicare-tacs/">
                    <div class="background is-purple"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-doc-h/">
                    <div className="background is-blue"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-le/">
                    <div className="background is-red"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Solothurner Spitäler AG - soH (SO)
                  <br />
                  Bürgerspital Solothurn
                </p>
                <p className="refKunden">Kunde seit 1998</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex3">
                  <a href="/dokumentationsloesungen/wicare-tacs/">
                    <div class="background is-purple"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-doc-h/">
                    <div className="background is-blue"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-le/">
                    <div className="background is-red"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Solothurner Spitäler AG - soH (SO)
                  <br />
                  Psychiatrische Dienste
                </p>
                <p className="refKunden">Kunde seit 1999</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex3">
                  <a href="/dokumentationsloesungen/wicare-tacs/">
                    <div class="background is-purple"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-doc-h/">
                    <div className="background is-blue"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-le/">
                    <div className="background is-red"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Solothurner Spitäler AG - soH (SO)
                  <br />
                  Spital Dornach
                </p>
                <p className="refKunden">Kunde seit 2001</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex2">
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    <div className="background is-green"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-doc-h/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Stadtspital Triemli (ZH)
                </p>
                <p className="refKunden">Kunde seit 2012</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex2">
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    <div className="background is-green"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-le/">
                    <div className="background is-red"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Kantonsspital Graubünden,
                  <br />
                  Hauptstandort,
                  <br />
                  Chur (GR)
                </p>
                <p className="refKunden">Kunde seit 1996</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex2">
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    <div className="background is-green"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-le/">
                    <div className="background is-red"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Kantonsspital Graubünden,
                  <br />
                  Kreuzspital,
                  <br />
                  Chur (GR)
                </p>
                <p className="refKunden">Kunde seit 2000</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex2">
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    <div className="background is-green"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-le/">
                    <div className="background is-red"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Kantonsspital Graubünden,
                  <br />
                  Fontana,
                  <br />
                  Chur (GR)
                </p>
                <p className="refKunden">Kunde seit 1997</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex2">
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    <div className="background is-green"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-le/">
                    <div className="background is-red"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Spital und Pflegeheim Appenzell,
                  <br />
                  Appenzell (AI)
                </p>
                <p className="refKunden">Kunde seit 2004</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    <div className="background is-green"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Kantonsspital St. Gallen,
                  <br />
                  St. Gallen (SG)
                </p>
                <p className="refKunden">Kunde seit 1993</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    <div className="background is-green"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Pychiatrie Baselland,
                  <br />
                  Liestal (BL)
                </p>
                <p className="refKunden">Kunde seit 2000</p>
              </article>
            </div>
          </section>
        </div>

            <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/wicare-lep/">WiCare|LEP</a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/wicare-lep/referenzen/">
                    Referenzen
                  </a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2023 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </DefaultLayout>
);

export default LEPReferenzen;
